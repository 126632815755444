div.Guide {
    width: 100%;
    padding: 30px 0;
    background-color: #FCE2C9;
    box-shadow: 7px 7px 7px #FAC898;
}

div.Guide .logo-box {
    cursor: pointer;
}

div.Guide .guide-box {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    text-align: center;
}

div.Guide .guide-box .guide-title {
    margin: 50px 0;
}

div.Guide .guide-box .guide-item {
    width: 100%;
    margin: 50px 0;
    display: flex;
}

div.Guide .guide-box .guide-item .guide-image {
    display: inline-block;
}
div.Guide .guide-box .guide-item.left .guide-image {
    order: 1;
}
div.Guide .guide-box .guide-item.right .guide-image {
    order: 2;
}

div.Guide .guide-box .guide-item .guide-image img {
    width: 22rem;
    margin: 15px;
    max-width: 400px;
}

div.Guide .guide-box .guide-item .guide-desc {
    display: inline-block;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 22rem - 30px);
    margin: 10px;
}
div.Guide .guide-box .guide-item.left .guide-desc {
    order: 2;
    text-align: left;
}
div.Guide .guide-box .guide-item.right .guide-desc {
    order: 1;
    text-align: right;
}

div.Guide .guide-box .guide-item .guide-desc label p {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 12px;
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
}

div.Guide .guide-box .guide-item .guide-desc .code-box {
    display: inline-block;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 15px 20px;
    background-color: #1E1E1E;
    color: white;
    cursor: pointer;
    text-align: left;
    -webkit-transition: background-color 1000ms ease;
    -ms-transition: background-color 1000ms ease;
    transition: background-color 1000ms ease;
}
div.Guide .guide-box .guide-item .guide-desc .code-box.active {
    background-color: #8e8e8e;
    -webkit-transition: background-color 100ms ease;
    -ms-transition: background-color 100ms ease;
    transition: background-color 100ms ease;
}

div.Guide .guide-box .guide-item .guide-desc .code-box code {
    font-size: 1rem;
    vertical-align: top;
    word-break: break-all;
}


@media (max-width: 767px) {
    div.Guide {
        box-shadow: none;
    }

    div.Guide .guide-box .guide-item {
        display: block;
    }

    div.Guide .guide-box .guide-item .guide-desc {
        width: calc(100% - 20px);
        align-items: center;
    }

    div.Guide .guide-box .guide-item .guide-desc label {
        text-align: center;
    }
}