div.Home {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FEEAEE;
}

div.Home main {
    overflow: auto;
    padding: 10px;
}

div.Home .search-box .search-bar {
    border-radius: 5px;
    box-shadow: 3px 3px 3px #CBBBBE;
    padding: 14px;
    background-color: #FFFFFF;
    width: 460px;
    overflow: auto;
    -webkit-transition: background-color 1000ms ease;
    -ms-transition: background-color 1000ms ease;
    transition: background-color 1000ms ease;
}
div.Home .search-box .search-bar.active {
    background-color: #FF6961;
    -webkit-transition: background-color 100ms ease;
    -ms-transition: background-color 100ms ease;
    transition: background-color 100ms ease;
}

div.Home .search-box .search-bar input {
    border: none;
    outline: none;
    font-size: 1.5rem;
    background-color: transparent;
    box-sizing: border-box;
    width: calc(100% - 40px);
    height: 30px;
    vertical-align: top;
    color: #7F7577;
}
div.Home .search-box .search-bar input::placeholder {
    color: #E4D2D6;
}

div.Home .search-box .search-bar img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    vertical-align: top;
    cursor: pointer;
    filter: invert(51%) sepia(7%) saturate(382%) hue-rotate(298deg) brightness(89%) contrast(79%);
}

div.Home .guide-box {
    text-align: center;
    margin-top: 30px;
}

div.Home .guide-box label {
    color: #7F7577;
    cursor: pointer;
    user-select: none;
}
div.Home .guide-box label:hover {
    text-decoration: underline;
}

div.Home .guide-box label > span {
    vertical-align: middle;
}



@media (max-width: 480px) {
    div.Home .logo-box img {
        width: 50px;
        margin-right: 6px;
    }

    div.Home .search-box .search-bar {
        border-radius: 3px;
        box-shadow: 2px 2px 2px #CBBBBE;
        padding: 10px;
        width: 280px;
    }

    div.Home .search-box .search-bar input {
        width: calc(100% - 29px);
        height: 23px;
    }

    div.Home .search-box .search-bar img {
        width: 23px;
        height: 23px;
        margin-left: 6px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    div.Home .logo-box img {
        width: 60px;
        margin-right: 8px;
    }

    div.Home .search-box .search-bar {
        border-radius: 4px;
        box-shadow: 2.5px 2.5px 2.5px #CBBBBE;
        padding: 12px;
        width: 370px;
    }

    div.Home .search-box .search-bar input {
        width: calc(100% - 34px);
        height: 26px;
    }

    div.Home .search-box .search-bar img {
        width: 26px;
        height: 26px;
        margin-left: 8px;
    }
}