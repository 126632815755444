div.Error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF6961;
}

div.Error main {
    overflow: auto;
    padding: 10px;
}

div.Error .logo-box {
    cursor: pointer;
}

div.Error .logo-box img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

div.Error .logo-box label {
    color: white;
}

div.Error .error-box {
    text-align: center;
    color: white;
}

div.Error .error-box h1 {
    font-size: 2rem;
}

div.Error .error-box p {
    font-size: 1.6rem;
}