.logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.logo-box img {
    filter: invert(29%) sepia(100%) saturate(3428%) hue-rotate(353deg) brightness(99%) contrast(96%);
    width: 70px;
    margin-right: 10px;
}

.logo-box label {
    color: #F73718;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-family: 'Righteous', cursive;
    user-select: none;
    cursor: inherit;
}