div.Bind {
    width: 100%;
    height: 100%;
    background-color: #CFCFC4;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.Bind label {
    font-size: 3rem;
    color: #5B5B4A;
    font-family: initial;
}